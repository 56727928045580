export const datadogConfig = {
  applicationId: '6ee4268b-6214-4fde-a154-5b539afae4f0',
  clientToken: 'pub170fc1817270a2bf5c275caf2dc5eabc',
  site: 'datadoghq.com',
  service: 'other-web-front-end',
  env: 'gatito',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: '3.0.1.2025-01-06-14-51-15-gatito',
  sessionSampleRate: 100,
  premiumSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackFrustrations: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
};
export const datadogLogsConfig = {
  clientToken: 'pub170fc1817270a2bf5c275caf2dc5eabc',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  forwardConsoleMessagesToLogs: true,
  sessionSampleRate: 100,
};
